@import '~bootstrap/scss/bootstrap';
@import '~react-custom-scroll/dist/customScroll.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import './var';
@import './mixins';
@import "./fonts.scss";

.gap-2 {
  padding: 0 3px
}

.containerstyle {
  margin: 0 10px;

}

.review_style {
  margin: 5px;
  border-bottom: 1px solid #d9d9d9;
}

.post-category {
  position: relative;
  
}
.home-page-id{
  .zip-code{
    color: $black;
  }
}
.slick-track {
  margin-bottom: 0px !important;
}

.slider-container {
  position: absolute;
  bottom: 40px;
  min-height: 0px;
  min-width: 0;
  // padding: 20px;
  max-width: 360px;
  .order-count{
    color: #fff;
    margin-left: 5px;
    background: #3b373759;
    margin-bottom: 5px;
    padding: 3px 6px;
    border-radius: 30px;
  }
  .slick-track{
    height: fit-content !important;
  }
  .slick-list {
    // height: 115px !important;
    padding: 4px !important;
  }
  
}
.StackGridCont{
  gap: 16px;
}
.center-mode {
  .item {
    // padding: 5px 0px;
    border-radius: 16px;
    background: rgba(79, 79, 79, 0.5);
    width: fit-content;
    max-width: 70%;
    display: flex;
    span {
      font-size: 14px;
      color: $white;
      padding: 4px 8px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  // color: red;
}

.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
  --bs-gutter-x: 0.0rem !important;
}

.register-master {
  padding: 0 5px;
}

.post-main-avatar {
  position: relative
}


* {
  // font-family: Roboto;
  font-style: normal;
  // color: #4F4F4F;
}

input:autofill,
input:-webkit-autofill {
  background-color: $white !important;
}

a {
  text-decoration: none !important;
  color: currentColor !important;
}

html,
body {
  font-size: $fz-14;
  background-color: $background-color !important;
}

//breadcrumb
$breadcrumb-font-size: 12px;
$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding-x: 0;
$breadcrumb-margin-bottom: 35px;
$breadcrumb-bg: null;
$breadcrumb-divider-color: #977575;
$breadcrumb-active-color: #977575;
$breadcrumb-divider: quote(">");
$breadcrumb-divider-flipped: $breadcrumb-divider;
$breadcrumb-border-radius: null;

#btn-back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  // display: none;
}

.not-found {
  background: url("../images/bg.png");
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font-size: 100px;
    color: #977575;
  }
}

.orderDay {
  border-radius: 50% !important;
  background-color: #00696B !important;
  color: #fff !important;
}

main {
  overflow: hidden;
  min-height: 82vh;
}


// .MuiInputLabel-outlined{
//   transform: translate(5px, 15px) scale(1) !important;
// }

input:-internal-autofill-selected {
  background-color: rgb(232, 240, 254) !important;
  background-image: none !important;
  color: -internal-light-dark(black, white) !important;
}

// input {
//   border: 3px solid grey;
//   border-radius: 3px;
// }

// input:-webkit-autofill {
//   border: 3px solid green !important;
//   background-color: rgb(232, 240, 254) !important;
// }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px $background-color inset;
  transition: background-color 5000s ease-in-out 0s;
}

.breadcrumb-item {
  color: $breadcrumb-active-color !important;
  font-size: $breadcrumb-font-size !important;
  font-weight: 300 !important;
  margin-top: 21px;

  @include media-breakpoint-down(sm) {
    font-size: 12px !important;
  }
}

.loader-cont {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

// .MuiInputLabel-outlined{
//   transform: translate(5px, 16px) scale(1) !important;
// }

// .MuiInputLabel-outlined{
//   transform: translate(5px, 15px) scale(1) !important;
// }

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  transform: translate(14px, 14px) scale(1) !important;
}

.extended {

  .navbar-toggler::before,
  .navbar-toggler-icon,
  .navbar-toggler::after {
    display: none;
  }
}

.ofCanvas {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh !important;
  z-index: -1;
}

.start-select {
  width: 93px;
  height: 54px !important;

  &:hover {
    border: none !important;
  }

  .MuiInput-underline:after,
  .MuiInput-underline:before,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none !important;
  }

  .MuiInputLabel-formControl {
    top: 15px;
    left: -4px !important;
    z-index: 0;
  }

  .MuiSelect-icon {
    display: none;
  }

  .MuiFormLabel-root {
    color: #977575;
  }
}

.add-cards .MuiInputBase-input {
  padding-left: 10px !important;
}

.close-navbar-toggler.collapsed {
  z-index: -1;
}

.close-navbar-toggler {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  cursor: pointer;
}

.breadcrumb-item.active,
.breadcrumb-item a {
  color: $breadcrumb-active-color !important;
  font-size: $breadcrumb-font-size !important;
  font-weight: 300 !important;

  @include media-breakpoint-down(sm) {
    font-size: 12px !important;
  }
}

.breadcrumb-item+.breadcrumb-item::before {
  content: $breadcrumb-divider !important;
  color: $breadcrumb-divider-color !important
}

.breadcrumb {
  background-color: $background-color !important;
  padding-left: $breadcrumb-item-padding-x !important;
  margin-bottom: 5px !important;
  padding-bottom: 0 !important;

  @include media-breakpoint-down(sm) {
    margin-bottom: 22px !important;
    padding-left: 10px !important;
  }
}

@include media-breakpoint-down(sm) {
  .page-top {
    position: relative;

    .map-link {
      position: absolute;
      top: 0px;
      right: 0px;
    }
  }
}

// .main-layout {
//   margin-bottom: 25px;
// }
.rdp-day_selected {
  background-color: $primary-color !important;
}

.dropdown-menu {
  // @include size(375px, 622px);
  width: 375px;
  border-radius: 30px !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25);
  padding: 34px 10px 20px !important;
  border: none !important;
  right: 50%;
  left: auto;
  top: 150%;
  transform: translateX(50%);

  .close {
    color: #C4C4C4 !important;
    opacity: 1;
    font-size: $fz-20;
    top: 14px;
    right: 34px;
    box-shadow: none;
    position: absolute
  }

  @include media-breakpoint-down-sm {
    position: fixed !important;
    top: 45px !important;
    z-index: 1100;
    width: 312px;
    max-width: 95%;
  }
}

@include media-breakpoint-down-sm {
  .navbar-collapse {
    background-image: linear-gradient(90deg, $primary-color 0%, $primary-light-shade-color 100%);
    position: fixed;
    height: 100vh;
    top: 0;
    display: flex;
    flex-direction: column;
    width: 266px;
    left: -266px;
    overflow: hidden;
    z-index: 1100;
    transition: all 1s;
    align-items: initial;
    padding: 31px 0;

    &.show {
      left: 0;
    }

    .navbar-nav {
      order: 2;
      width: 100%;
    }
  }
}

.my-master,
.any-master {
  margin-top: 20px;
}

.my-master .icon-heart:before,
.any-master .icon-heart:before {
  font-size: 30px;
  color: $gray-color;
  cursor: pointer;
}

.my-master {
  .icon-heart:before {
    color: $primary-color;
  }
}

.dropdown-toggle {
  padding: 0 !important;

  &::after {
    font-family: 'icomoon' !important;
    content: "\e91d" !important;
    border: none !important;
    color: $white !important;
    font-size: 34px !important;

    @include media-breakpoint-down-md {
      font-size: 28px !important;
    }

    @include media-breakpoint-down-sm {
      font-size: 23px !important;
    }
  }
}

.autocomplete-icon {
  .icon-cross {
    color: $black;
    font-size: $fz-10 !important;
    cursor: pointer;

    &::before {
      color: $black;
    }
  }
}

.navbar-toggler {
  @include size(38px, 16px);
  position: relative;

  &::after,
  &::before {
    content: '';
    @include size(100%, auto);
    // @include border-radius(5px);
    border-top: 2px solid $white;
    position: absolute;
    left: 0;
  }

  &::after {
    bottom: 0;
  }

  &::before {
    top: 0;
  }

  .navbar-toggler-icon {
    background-image: none;
    @include size(100%, auto);
    // @include border-radius(5px);
    border-top: 2px solid $white;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

// slider

.home-slider-cont {
  margin-top: 0px;
  .slick-next,
  .slick-prev {
    top: 50%;
    transform: translateY(-50%);
  }

  .slick-next:before,
  .slick-prev:before {
    color: $primary-light-shade-color !important;
    // background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
  }

  .slick-next:before,
  .slick-prev:before {
    font-size: 30px;
  }

  .slick-prev {
    left: 40px;
    z-index: 99;

    @include media-breakpoint-down-lg {
      left: 25px;
      z-index: 99;
      top: 110px;
    }
  }

  .slick-next {
    right: 40px;

    @include media-breakpoint-down-lg {
      right: 30px;
      top: 110px;
    }
  }

  .slick-initialized {
    a {
      padding-right: 10px !important;
    }
  }
}

.article-slider-cont {
  @include media-breakpoint-down-sm {
    // height: 220px !important;

    .slick-slide {
      height: 100% !important;
    }
  }
}

.slick-slider {
  width: 100%;
}

.infinite-scroll-component {
  overflow: hidden !important;
}

.cross-icon {
  cursor: pointer;
  margin-right: -16px;
  margin-top: -9px;
}

.leaflet-bottom {
  display: none !important;
}

.post-page-slider {
  margin-top: 3px;
  position: relative;
  video {
    width: 100%;
    //border-radius: 10px;
    object-fit: cover;
  }
  .slick-track{
    margin-bottom: 0 !important;
  }
  @include media-breakpoint-down-sm {
    margin-top: 8px;
  }

  .slick-dots li button:before {
    font-size: 22px;

    @include media-breakpoint-down-sm {
      font-size: 6px;
    }
  }

  .slick-next {
    right: 45px;

    @include media-breakpoint-down-sm {
      right: 25px;
    }
  }

  .slick-prev {
    left: 20px;
    z-index: 1;
  }

  .slick-next:before,
  .slick-prev:before {
    color: $white;
    font-size: 45px;

    @include media-breakpoint-down-sm {
      font-size: 25px;
    }
  }

  .slick-dots {
    bottom: 32px !important;

    @include media-breakpoint-down-sm {
      bottom: -14px !important;
    }
  }

  .slick-dots li.slick-active button:before {
    opacity: .75;
    color: $white;

    @include media-breakpoint-down-sm {
      color: $primary-color;
    }
  }

  .slick-dots li button:before {
    width: 22px;
    height: 22px;
    color: $white;

    @include media-breakpoint-down-sm {
      color: black;
    }
  }
}






.posts-page-slider {
  position: relative;
  // margin-top: 27px;

  // @include media-breakpoint-down-sm {
  //   margin-top: 8px;
  // }

  // .slick-dots li button:before {
  //   font-size: 22px;

  //   @include media-breakpoint-down-sm {
  //     font-size: 6px;
  //   }
  // }
  .slick-track{
    margin-bottom: 0 !important;
  }
  .slick-next {
    right: 20px;

    @include media-breakpoint-down-sm {
      right: 20px;
    }
  }

  .slick-prev {
    left: 20px;
    z-index: 1;
  }

  .slick-next:before,
  .slick-prev:before {
    color: $white;
    font-size: 20px;

    @include media-breakpoint-down-sm {
      font-size: 25px;
    }
  }

  .slick-dots {
    bottom: 32px !important;

    @include media-breakpoint-down-sm {
      bottom: -14px !important;
    }
  }

  .slick-dots li.slick-active button:before {
    opacity: .75;
    color: $white;

    @include media-breakpoint-down-sm {
      color: $primary-color;
    }
  }

  .slick-dots li button:before {
    width: 22px;
    height: 22px;
    color: $white;

    @include media-breakpoint-down-sm {
      color: black;
    }
  }
}






.post-page-slider {

  // .video-react {
  //   padding-top: 0% !important;
  // }
  video {
    padding-top: 0% !important;
  }
}

.master-gallery {
  width: 100%;

  .video-react {
    margin-bottom: 0;
    height: 100%;
  }

  .video-react .video-react-video {
    height: 275px !important;

    @include media-breakpoint-down-sm {
      height: 110px !important;
    }
  }
}

.post-page-slider {

  // .video-react .video-react-video {
  //   height: 715px !important;
  // }
  video {
    height: 715px !important;
  }
}

@media screen and (max-width: 768px) {
  .post-page-slider {

    // .video-react .video-react-video {
    //   height: 500px !important;
    // }
    video {
      height: 700px !important;
    }
  }
}

.masters-post {
  .video-react {
    .video-react-video {
      height: 360px !important;
    }
  }
}

.main-post {
  width: 100%;
  height: 100% !important;

  video {
    // height: 800px !important;
    width: 100%;
    border-radius: 0px;
    object-fit: cover;
    object-position: center;

    @include media-breakpoint-down-md {
      height: 100% !important;
    }
  }

  // .video-react {
  //   .video-react-video {
  //     height: 800px !important;
  //     object-position: center;
  //   }

  //   @include media-breakpoint-down-md {
  //     .video-react-video {
  //       height: 400px !important;
  //     }
  //   }
  // }
}

@include media-breakpoint-down-md {
  // .slick-track{
  //   height: 400px;
  // }
}


.video-react {
  border-radius: 10px;
  // margin-bottom: 23px;
  padding-top: 0% !important;
  height: 100%;

  .video-react-video {
    object-fit: cover;
    object-position: center;
    border-radius: 0px;
    width: 100%;
    height: 100%;
    // height: 127px !important;
  }

  @include media-breakpoint-down-md {
    height: 400px !important;
  }

  @include media-breakpoint-down-sm {
    height: 300px !important;
  }
}

.masters {
  @include media-breakpoint-down-md {
    .video-react {
      height: 400px !important;
    }
  }
}

#map {
  height: 100%;
}

.leaflet-container {
  height: 100% !important;
}

.password-input {
  margin-bottom: 20px !important;
}

.home-page-id .video-react-big-play-button {
  display: none !important;
}

.home-page .video-react-big-play-button {
  display: none !important;
}

.video-react-big-play-button {
  top: 125px !important;
}

.video-react-has-started .video-react-control-bar,
.video-react-control-bar-auto-hide {
  display: none !important;
}

.marker-cluster-custom {
  background-image: url('../public/m1.png');
  @include size(53px);
  background-size: contain;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.leaflet-div-icon {
  object-fit: cover;
  border-radius: 50%;
}

// .ReactGridGallery{
//   width: 100%;
// }
// .ReactGridGallery_tile-viewport{
//   border-radius: 10px !important;
//   img{
//       width: 100% !important;
//       height:100% !important ;
//       margin-left: 0 !important;
//       // @include imageSize(cover, center)
//       object-fit: cover !important;
//       object-position: top !important;
//   }
// }
// .ReactGridGallery_tile{
//   margin-bottom: 10px !important;
//   border-radius: 10px;
// }
.react-switch-handle {
  // switch
  // height: 20px !important;
  // width: 20px !important;
  background: linear-gradient(90deg, $primary-color 0%, $primary-light-shade-color 96.37%) !important;
  // border-radius: 50px !important;
  // top: -3px !important;
  // transform: translateX(15px);
  // left: -12px !important;
}

.react-switch-bg {
  // height: 14px !important;
  // width: 34px !important;
  // background: #00696B !important;
  opacity: 0.38 !important;
  border-radius: 7px !important;

  svg {
    display: none !important;
  }
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: $primary-color !important;
}

.MuiSwitch-colorSecondary.Mui-disabled {
  color: #bdbdbd !important;
}

.MuiSwitch-track {
  color: #00696B !important;
}

.MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
  background-color: #00696B !important;
}

.map-search {
  width: 100%;
  margin-top: 24px;

  .MuiAutocomplete-root {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 3px 8px rgba(40, 26, 27, 0.1);
    border-radius: 7px;

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #F7F7F7;
    }

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
}

.MuiAutocomplete-root {
  // width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 3px 8px rgba(40, 26, 27, 0.1);
  border-radius: 7px;

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
}

.MuiOutlinedInput-notchedOutline {
  border: 1px solid #F7F7F7 !important;
}

.map-service-select {
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #F9989C !important;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #F9989C !important;
  }
}

.fa-heart {
  margin-top: 20px;
  font-size: 30px;
  margin-right: 40px;

  // @include media-breakpoint-down-md{
  //    margin-top: 0;
  // }
  @include media-breakpoint-down-sm {
    margin-right: 0;
    font-size: $fz-16;
  }
}

.MuiRating-root {
  color: #E3898D !important;
}

.MuiRating-root.Mui-disabled {
  opacity: 0.8 !important;
}

.MuiRating-iconEmpty {
  color: #FFDCDE !important;
}

.empty_text {
  color: #977575;
  font-size: $fz-18;
}

.responsive-calendar .Calendar__day.-ltr {
  // margin: 0 39px;
  margin-bottom: 10px;
  position: relative;

  // width: 80px;
  // @include media-breakpoint-down-lg{
  //   margin: 0 8px;
  // }
  @include media-breakpoint-down-sm {
    margin: 0px;
    margin-bottom: 10px;
  }
}


.pinkDay:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background: #448AFF;
  color: #fff;
}

.greenDay:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background: #BBFBC5;
  ;
}

// Calendar

.text-cont {
  width: 50%;
  margin: auto;
  background-color: #87E2AB;
  color: #fff;
  font-size: 9px;
}

.available-cont {
  display: flex;
  position: absolute;
  bottom: -10px;
  width: 100%;
  justify-content: center;
}

.class1,
.class2,
.class3 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #87E2AB;
  color: #fff;
  margin: 0px 2px !important;
}

.class2 {
  background: #e2878b;
}

.class3 {
  background: #7087F5;
}

// .class1::before::before{
//   content: '';
//   width: 10px;
//   height: 10px;
//   border-radius: 50%;
//   background: #F9989C;
//   position: absolute;
//   bottom: -5px;
// }
// .class1::after{
//   content: '';
//   width: 10px;
//   height: 10px;
//   border-radius: 50%;
//   background: #977575;
//   position: absolute;
//   bottom: -5px;
// }
.react-datepicker__input-container {
  input {
    border: none;
    box-shadow: 0px 3px 8px rgb(40 26 27 / 10%);
    border-radius: 7px;
    margin-bottom: 0px;
    padding: 10px;

    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
  }
}

.register-master .MuiAutocomplete-root {
  @include media-breakpoint-down(sm) {
    width: 100% !important;
  }
}

.lazy-load-image-background {
  width: 100%;
}

.responsive-calendar {
  width: 100% !important;
  background: none !important;
  box-shadow: none !important;
  margin: 0 auto !important;
  font-size: 48px;
  min-height: 29.7em !important;

  .Calendar__sectionWrapper {
    min-height: 55em;

    @include media-breakpoint-down(lg) {
      min-height: 41em;
    }

    // @include media-breakpoint-down(md){
    //     min-height: 25em;
    // }
    @include media-breakpoint-down(sm) {
      min-height: 29em;
    }
  }

  .Calendar__day.-ltr {
    min-height: .6em;
    font-size: 48px;

    @include media-breakpoint-down(lg) {
      font-size: 35px;
    }

    @include media-breakpoint-down(md) {
      font-size: 28px;
    }

    @include media-breakpoint-down(sm) {
      min-height: 2.6em;
      font-size: 1.45em;
      // width: 38px;
      // margin: 0 10px !important;
    }
  }

  .Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
    content: "";
    display: none !important;
  }

  .Calendar__day.-today {
    // background: #87E2AB;
    color: var(--cl-color-black) !important;
    // color: $white;
    margin-bottom: 10px;
    opacity: 1 !important;

    &::after {
      content: "";
      border-radius: 50%;
      margin-bottom: 10px;
      background: #448AFF;
      border: 1px solid #448AFF;
      color: #fff;
      width: 80px !important;
      position: absolute;
      height: 80px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;

      @include media-breakpoint-down-md {
        width: 60px !important;
        height: 60px;
      }

      @include media-breakpoint-down-sm {
        width: 35px !important;
        height: 35px;
      }
    }

    // &::after{
    //   content: "";
    //   border-radius: 50%;
    //   border: 1px solid #87E2AB;
    //   background: #87E2AB;
    //   color: #fff;
    //   width: 80px !important;
    //   margin: auto;
    //   position: absolute;
    //   height: 80px;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   z-index: -1;
    //   @include media-breakpoint-down-md {
    //     width: 60px !important;
    //     height: 60px;
    //   }

    //   @include media-breakpoint-down-sm {
    //     width: 35px !important;
    //     height: 35px;
    //   }

    // }

  }

  .available-day {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      content: "";
      border-radius: 50%;
      background: #87E2AB !important;
      color: #fff;
      width: 80px !important;
      margin: auto;
      position: absolute !important;
      height: 80px !important;
      top: 50% !important;
      left: 50% !important;
      bottom: auto !important;
      transform: translate(-50%, -50%) !important;
      opacity: 1 !important;
      z-index: -1;

      @include media-breakpoint-down-md {
        width: 60px !important;
        height: 60px !important;
      }

      @include media-breakpoint-down-sm {
        width: 35px !important;
        height: 35px !important;
      }

    }

    // @include media-breakpoint-down(sm) {
    //   padding: 0px 10px !important;
    //   margin: 2px 3px !important;
    //   margin-bottom: 10px !important;
    // }
  }

  .Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
    background: none;
  }

  // .Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
  //   display: none;
  // }

  .Calendar__day {
    display: block;
    // width: calc(56% / 7) ;
    font-weight: $medium ;
    margin-bottom: 8px;
    padding: calc(0.25em - 15px) 0;
    margin-bottom: 10px;

    // @include media-breakpoint-down(lg){
    //     margin: 0 24px;
    // }
    // @include media-breakpoint-down(md){
    //     margin: 0 15px ;
    // }
    @include media-breakpoint-down(sm) {
      width: 14.28571%;
      text-align: center;
      padding: calc(.25em - 1px) 0;
      min-height: 2.6em;
      font-size: 1.45em;
      margin: 0;
      margin-bottom: .3em;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .Calendar__section {
    padding: 0 8.2em;

    @include media-breakpoint-down(sm) {
      top: 0;
      padding: .5em 3.2em 0;
      left: 0;
    }
  }

  .Calendar__day.-selected,
  .Calendar__day.-selectedStart,
  .Calendar__day.-selectedEnd {
    margin-bottom: 10px;
    background: none;

    // background: #448AFF;
    // border: 1px solid #448AFF;
    // color: #fff;
    &::after {
      content: "";
      border-radius: 50%;
      margin-bottom: 10px;
      background: #448AFF;
      border: 3px solid rgba(0, 0, 0, 0.688);
      color: #fff;
      width: 80px !important;
      position: absolute;
      height: 80px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;

      @include media-breakpoint-down-md {
        width: 60px !important;
        height: 60px;
      }

      @include media-breakpoint-down-sm {
        width: 35px !important;
        height: 35px;
      }
    }

    // color:#3C3C3B;



  }

  .Calendar__header {
    padding: 2em 13.9em;

    @include media-breakpoint-down(sm) {
      padding: 2em 2.9em;
    }
  }

  .Calendar__weekDays {
    padding: 0 6.6em;
    margin-top: 69px;
    margin-bottom: 39px;

    @include media-breakpoint-down(sm) {
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 0 2.6em;
      color: #969696;
      font-size: $fz-12;
    }
  }

  .Calendar__weekDay {
    font-weight: $medium ;
    font-size: 18px;
    line-height: 21px;
    color: #3C3C3B;
    text-decoration: none;

    @include media-breakpoint-down(sm) {
      color: #969696;
      font-size: $fz-12;
    }
  }

  .Calendar__monthYearContainer {
    font-weight: $medium ;
    font-size: 24px;
    color: $text-color ;

    @include media-breakpoint-down(sm) {
      font-size: $fz-14;
    }
  }

  .Calendar__day.-weekend:not(.-selected):not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
    color: $text-color !important;
  }
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
  background-color: $background-color !important;
}

.MuiTabs-indicator {
  background: linear-gradient(90deg, $primary-color 0%, $primary-light-shade-color 96.37%) !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: $title-color !important;
}

.PrivateTabIndicator-colorPrimary-3 {
  background: linear-gradient(90deg, $primary-color 0%, $primary-light-shade-color 96.37%) !important;
}

.MuiTabs-root {
  button {
    border-bottom: 2px solid #EEEEEE !important;
  }
}

.MuiFormControl-root {
  width: 100%;
}

.select-input {
  margin-bottom: 22px;
  width: 340px;
  max-width: 100%;
  height: inherit;
}

.MuiCheckbox-root {
  svg {
    fill: $primary-color;
  }
}

.MuiFormLabel-root.Mui-focused {
  color: $primary-light-shade-color !important;
}

.my-profile .Mui-focused.MuiInput-underline:after {
  border-bottom: 2px solid $primary-light-shade-color !important;
}

.btn-link {
  font-weight: 400;
  text-decoration: underline;
  position: absolute;
  right: 0;
  top: 11px;
}

//stepper
.register-stepper {
  .MuiStepper-root {
    padding: 0;
  }

  .MuiStepper-alternativeLabel {
    width: 490px;
    max-width: 100%;
  }

  .MuiPaper-root {
    background-color: #FEFCFA
  }

  .MuiStepConnector-lineHorizontal {
    @include size(20px);

    @include media-breakpoint-down-sm {
      @include size(10px);
    }

    border: 0;
    margin: auto;
    border-radius: 50%;
    opacity: 0.5;
    background: linear-gradient(90deg, #F9989C 0%, #FBB0B5 96.37%),
    #F7F7F7 !important;
  }

  .MuiStepConnector-alternativeLabel {
    left: calc(-93% + 20px) !important;
    right: calc(48% + 20px) !important;
  }

  .WithStyles\(ForwardRef\(StepConnector\)\)-alternativeLabel-27 {
    top: 32px !important;
  }

  .MuiStepLabel-root {
    align-items: flex-start;
  }

  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 25px;
    text-align: start;
  }

  .MuiStepLabel-alternativeLabel {
    font-size: $fz-18;
    line-height: 21px;
    color: $gray-color;
  }

  .MuiStepLabel-iconContainer {
    @include size(82px);
    background-color: $white;
    z-index: 1;
    color: $text-color;
    font-size: 28px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.1);

    @include media-breakpoint-down-sm {
      @include size(42px);
      font-size: $fz-18;
      ;
    }
  }

  .makeStyles-active {
    color: $white;
    box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.1);
    background-image: linear-gradient(90deg, $primary-color 0%, $primary-light-shade-color 96.37%);
  }

  .MuiStepLabel-label.MuiStepLabel-active,
  .MuiStepLabel-label.MuiStepLabel-completed {
    color: $gray-color;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #E0E0E0;
  border-radius: 2.5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary-color;
  border-radius: 2.5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary-color;
}

//chats
.chats {
  .cs-main-container {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow: auto;
  }

  .cs-main-container--responsive .cs-conversation-list .cs-conversation__content,
  .cs-main-container--responsive .cs-sidebar .cs-conversation-list .cs-conversation__content {
    @include media-breakpoint-down-sm {
      display: flex;
    }
  }

  .cs-main-container--responsive>.cs-sidebar.cs-sidebar--left,
  .cs-main-container>.cs-sidebar.cs-sidebar--left {
    @include media-breakpoint-down-sm {
      flex-basis: 100%;
      min-width: 100%;
    }
  }

  .cs-main-container--responsive>.cs-sidebar.cs-sidebar--left {
    @include media-breakpoint-down-md {
      min-width: calc(3em + 40px) !important;
    }

    @include media-breakpoint-down-sm {
      display: flex;
      width: 100% !important;
      min-width: 100% !important;
    }
  }

  .cs-message-group--outgoing .cs-message-group__messages .cs-message {
    flex: 1;
    margin-right: 5px;
  }

  .cs-message-group--outgoing .cs-message-group__messages .cs-message .cs-message__content {
    background-color: #FCF0EF !important;
    border-radius: 10px 10px 10px 0px !important;
  }

  .cs-message-list .cs-message.cs-message--outgoing,
  .cs-message-list .cs-message-group.cs-message-group--outgoing {
    margin-bottom: 30px !important;
  }

  .cs-avatar>img {
    object-fit: cover !important;
  }

  .cs-search__clear-icon {
    color: $primary-color !important;
    @include size(20px);
  }

  font-weight: $regular;

  .cs-search {
    margin: 0 !important;
    margin-right: 20px !important;
    margin-bottom: 25px !important;
    height: 56px !important;
    display: flex !important;
    align-items: center !important;
  }

  .cs-search__input {
    background-color: $white;
    font-size: $fz-16;
    line-height: 24px;
    color: $gray-color;
    outline: none !important;
    border: none !important;

    &::placeholder {
      color: $gray-color;
    }
  }

  .cs-search__search-icon {
    display: none;
  }

  .cs-main-container,
  .cs-sidebar--left {
    border: none;
    background-color: $background-color !important;
  }

  .cs-main-container>.cs-sidebar.cs-sidebar--left {
    border: none
  }

  .cs-conversation {
    margin-right: 20px;

    @include media-breakpoint-down(md) {
      box-shadow: none !important;
      background: none !important;
    }

    @include media-breakpoint-down(sm) {
      background: #FFFFFF !important;
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.17) !important;
      margin-right: 0px;
    }
  }

  .cs-conversation-header {
    padding: 0px !important;
    padding-bottom: 7px !important;
    box-shadow: none !important;
    border-bottom: 1px solid #EFEFEF !important;
    min-height: 40px !important;
    background: #FEFCFA !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
  }

  .cs-conversation-header .cs-avatar.cs-avatar--md {
    @include size(40px);
  }

  .cs-conversation__name,
  .cs-conversation.cs-conversation--active .cs-conversation__name {
    font-weight: $medium;
    font-size: $fz-16;
    line-height: 24px;
    letter-spacing: 0.16px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: $text-color;
  }

  .cs-conversation__info,
  .cs-conversation.cs-conversation--active .cs-conversation__info {
    font-size: $fz-14;
    line-height: 16px;
    color: $gray-color;
  }

  .cs-message__content {
    font-weight: $regular;
    font-size: $fz-14;
    line-height: 16px;
    color: #848484 !important;
    margin-bottom: 10px;
    padding: 18px !important;
  }

  .cs-message--outgoing .cs-message__content {
    background: #FCF0EF !important;
    // transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .cs-message--incoming .cs-message__content {
    background: #F7F7F7 !important;
  }

  .ps__thumb-y,
  .cs-message-separator::before,
  .cs-message-separator::after,
  .cs-typing-indicator__dot {
    background-color: $primary-color;
  }

  .cs-message-separator,
  .cs-typing-indicator__text {
    color: $primary-color;
  }

  .ps__rail-y:hover>.ps__thumb-y,
  .ps__rail-y:focus>.ps__thumb-y,
  .ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: $primary-color !important;
  }

  .cs-message-input__content-editor-wrapper {
    background: #F7F7F7;
    opacity: 0.98;
    border-radius: 10px;

    .cs-message-input__content-editor {
      background: #F7F7F7;
    }
  }

  .cs-button--send {
    color: $primary-color !important;
  }

  .chats-items {
    height: 45px;
  }

  .cs-conversation>.cs-avatar {
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
  }

  .cs-avatar.cs-avatar--md {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;

    @include media-breakpoint-down(sm) {
      margin-right: 15px !important;
    }
  }
}

.cs-conversation.cs-conversation--active {
  // background: #FFFFFF;
  // border-radius: 7px;
  box-shadow: none !important;
}

.cs-message-list .cs-message.cs-message--incoming,
.cs-message-list .cs-message-group.cs-message-group--incoming {
  margin-bottom: 30px !important;
}

.cs-message-group--incoming {
  width: fit-content !important;
}

.chat_message_group_cont__2zgP1 {
  width: 100% !important;
}

.cs-message-group--incoming .cs-message-group__messages .cs-message .cs-message__content {
  background: #F7F7F7 !important;
  border-radius: 10px 10px 10px 0px !important;
}

.cs-message-list__scroll-wrapper>.cs-message,
.cs-message-list__scroll-wrapper>.cs-message-group,
.cs-message-list .cs-typing-indicator,
.scrollbar-container {
  max-width: 100% !important;
  background-color: $background-color !important;
}

.cs-message {
  flex-direction: column-reverse !important;
  flex: 1;

  // margin-bottom: 30px !important;
  img {
    @include size(24px);
  }
}

.cs-message--incoming.cs-message--avatar-spacer {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.cs-conversation-header {
  background: $background-color !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
  padding: .5em 0.9em !important;
  border: none !important;
}

.cs-conversation__unread {
  @include size(24px);
  background: linear-gradient(90deg, $primary-color 0%, $primary-light-shade-color 96.37%), #C4C4C4;
  border-radius: 50% !important;
  top: 10px !important;
  right: .8em !important;
  bottom: 2.8em !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cs-conversation-header__content .cs-conversation-header__user-name,
.cs-conversation-header__content .cs-conversation-header__info {
  background: $background-color !important;
}

.cs-button svg {
  width: 14px !important;
  color: #C4C4C4;
}

.cs-button--arrow,
.cs-button--attachment {
  color: $primary-color !important;
}

.cs-chat-container .cs-message-input {
  border-top: none !important;
}

.selected-masters {
  .icon-search {
    margin: auto;
    margin-left: 19px;
    font-weight: 700;

    &::before {
      color: $title-color;
    }
  }
}

.map-cont {
  .container {
    padding: 0;
  }

  .icon-arrow-left,
  .icon-cross {
    font-size: 30px;
    cursor: pointer;
  }

  .icon-cross {
    font-size: 20px;
    cursor: pointer;
  }

  .icon-map-label {
    font-size: 25px;
  }

  .icon-search {
    margin-right: 11px;

    &::before {
      color: $black;
      font-weight: 700;
      font-size: 14px;
    }
  }

  .gm-style-pbc {
    img {
      display: none !important;
    }
  }
}

//custom-scroll
.rcs-custom-scroll .rcs-inner-handle {
  background-color: $primary-color;
}

//icons
.header-like-icon {
  .icon-heart {
    &::before {
      color: $white !important;
    }

    font-size: 34px;
    padding: 0;
    margin: 0 40px 0 35px;

    @include media-breakpoint-down-lg {
      margin: 0 20px 0 15px;
    }

    @include media-breakpoint-down-md {
      font-size: 24px;
    }
  }
}

.home-map-page {
  .icon-map-label {
    font-size: 25px;
    padding: 0;
    margin-right: 8px;

    @include media-breakpoint-down-sm {
      font-size: $fz-20;
    }
  }
}

.comment-bottom-icons {

 // margin-top: 10px;
 // padding: 0 5px;
  position: relative;
  bottom: 40px;

  .icon-heart,
  .icon-chat_1,
  .icon-viewing {
    margin-right: 10px;
  }

  .icon-heart {
    &::before {
      color: #FAA8AC;
    }
  }
}

.header-logo {
  @include size(282px, 54px);

  @include media-breakpoint-down-sm {
    @include size(134px, 25px)
  }
}

.navbar-brand {
  img {
    cursor: pointer;

    @include media-breakpoint-down-md {
      max-width: 145px;
    }
  }
}

.cards-cont {
  position: relative;

  .icon-map-label {
    line-height: normal;
    display: none;
    margin-right: 5px;

    &::before {
      color: #D1D1D1;
      // margin-right: 10px;
    }
  }

  .card-map-label,
  .masters {
    .icon-map-label {
      &::before {
        font-size: 25px;
        margin-right: 13px;
      }
    }

  }

  .mobile-map-label {
    .icon-map-label {
      &::before {
        color: #9D9D9D;
        font-size: 14px;
      }
    }
  }
}

.my-info {
  .icon-map-label {
    margin-right: 5px;
  }
}

.icon-log-out {
  margin-right: 17px;
}

.masters-add-button {
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  @include size(100%, 275px);

  @include media-breakpoint-down-md {
    @include size(100%, 170px);
  }

  @include media-breakpoint-down-sm {
    @include size(100%, 110px);
  }

  .icon-plus {
    top: 0;
    left: 0;
    font-size: 35px;
    font-weight: 800;

    &:before {
      color: #E2878B;
    }

    @include media-breakpoint-down-sm {
      font-size: 42px;
    }
  }
}

//master page icons
.icon-services,
.icon-testimonials {
  &:before {
    color: #F99DA1 !important;
    font-size: 30px;

    @include media-breakpoint-down-sm {
      font-size: 13px;
    }
  }
}

.icon-testimonials1 {
  &:before {
    color: $white !important;
    margin-right: 10px;

    @include media-breakpoint-down-sm {
      font-size: 13px;
    }
  }
}

.icon-publications {
  font-family: "icomoon" !important;

  &:before {
    color: #F99DA1 !important;
    font-size: 30px;

    @include media-breakpoint-down-sm {
      font-size: 13px;
    }
  }
}

.icon-Information {
  font-family: "icomoon" !important;

  .path1,
  .pat2,
  .path3,
  .path4,
  .path5,
  .path6,
  .path7,
  .path8 {
    &:before {
      color: #F99DA1 !important;
      font-size: 30px;

      @include media-breakpoint-down-sm {
        font-size: 13px;
      }
    }
  }
}

.active-master-button {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $text-color;

  div {
    background: linear-gradient(90deg, $primary-color 0%, $primary-light-shade-color 96.37%), #FCF0EF !important;
  }

  @include media-breakpoint-down-sm {
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 0;
      margin: -1px;
      /* border-radius: inherit; */
      background: linear-gradient(90deg, $primary-color 0%, $primary-light-shade-color 96.37%), #FCF0EF;
      width: 100%;
      height: 2px;
    }
  }

  .icon-services,
  .icon-testimonials {
    &:before {
      color: $white !important;
      font-size: 30px;

      @include media-breakpoint-down-sm {
        font-size: 13px;
      }
    }
  }

  .icon-publications {
    &:before {
      color: $white !important;
      font-size: 30px;

      @include media-breakpoint-down-sm {
        font-size: 13px;
      }
    }
  }

  .icon-publications,
  .icon-Information {

    .path1,
    .path2,
    .path3,
    .path4,
    .path5,
    .path6,
    .path7,
    .path8 {
      &:before {
        color: $white !important;
        font-size: 30px;

        @include media-breakpoint-down-sm {
          font-size: 13px;
        }
      }
    }
  }
}

.chat-icon-cont {
  position: fixed;
  bottom: 30px;
  right: 4%;

  .icon-chat {
    font-size: 30px;
  }
}

.payment-cards {
  .icon-plus {
    font-size: 18px;
    margin-right: 18px;

    &::before {
      color: #AFAFAF;
    }

  }
}

.payment-icons {
  .icon-credit-card {
    margin-right: 14px;

    .path1,
    .path2,
    .path3,
    .path4,
    .path5,
    .path6 {
      &:before {
        color: #afafaf;
        font-size: 20px;

        @include media-breakpoint-down-sm {
          font-size: 13px;
        }
      }
    }
  }

  .icon-pay {
    margin-right: 14px;
  }
}

.active-input_card {
  .icon-credit-card {

    .path1,
    .path2,
    .path3,
    .path4,
    .path5,
    .path6 {
      &:before {
        color: $primary-light-shade-color;

      }
    }
  }
}

.add-icon-card {
  .icon-credit-card {
    margin-left: 14px;

    .path1,
    .path2,
    .path3,
    .path4,
    .path5,
    .path6 {
      &:before {
        font-size: 20px;
        // @include media-breakpoint-down-sm{
        //   font-size: 13px;
        // }
      }
    }
  }
}

.comment-item {

  .active-input,
  .comment-input {
    margin-left: 38px;
    margin-bottom: 5px;
    margin-top: 21px;
    width: calc(100% - 38px);
  }
}

.MuiButton-root {
  padding: 0px !important;
  line-height: 0px !important;
  min-width: 70px !important;
  /*max-width: fit-content !important;*/
  font-size: 20px !important;
  color: #ffffff !important;
  text-shadow: 1px 1px 2px black;

  &:hover {
    background-color: white !important;
  }
}

.MuiList-padding {
  padding: 32px !important;
  padding-bottom: 16px !important;
}

.MuiMenuItem-root {
  padding-bottom: 16px;
  min-height: 48px !important;
}

.MuiButton-text {
  padding: 0px !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $primary-light-shade-color !important;
}

// .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline:focus{
//   border-color: $primary-light-shade-color !important;
// }

.MuiInputBase-root {
  height: 44px !important;
}

.MuiInputBase-input {
  padding: 0 !important;
}

.active-firstinput_card {
  border: 1px solid $primary-light-shade-color;
}

.active-input_card {
  // padding-left: 15px;
  position: relative;
  display: flex;
  height: 44px;
  align-items: center;
  background-color: $white;
  width: 100%;
  margin-bottom: 20px;
  // border: 1px solid $primary-light-shade-color;
  border-radius: 5px;

  &:nth-child(2) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .icon-card {
    margin-left: 14px;

    .path1,
    .path2,
    .path3,
    .path4,
    .path5,
    .path6 {
      &:before {
        color: $primary-light-shade-color;
      }
    }
  }

}

.MuiInputBase-input {
  height: 30px !important;
}

.registration-end {
  .icon-services {
    &:before {
      font-size: 80px;
      color: $primary-light-shade-color;

      @include media-breakpoint-down-sm {
        font-size: 50px;
      }
    }
  }
}

.dislike {
  .icon-heart {
    &:before {
      color: white;
    }
  }
}

.like {
  .icon-heart {
    &:before {
      color: #e2878b;
    }
  }
}

.WithStyles\(ForwardRef\(TextField\)\)-root-8 .MuiInput-underline:after,
.MuiInput-underline:after {
  border-bottom: 2px solid $primary-light-shade-color !important;
}

// .MuiInput-underline:hover:not(.Mui-disabled):before{
//   border-bottom: none !important;
// }
//.MuiOutlinedInput-root
.my-profile {
  .MuiFilledInput-root {
    width: 340px;
    background: none;
    margin-bottom: 16px;

    &:hover {
      background: none;
    }
  }

  .MuiFilledInput-root.Mui-focused {
    background: none;
  }

  .MuiSelect-select:focus {
    background: none;
  }

  .MuiFormLabel-root {
    font-weight: $medium;
    font-size: $fz-16;
    line-height: 14px;
    color: #DADADA;
    padding: 0 !important;
  }

  .MuiFilledInput-underline:after,
  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:hover:before,
  .MuiInput-underline:after,
  .MuiInput-underline:before,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid #EFEFEF !important;
  }

  .MuiInput-underline.Mui-error:after {
    border-bottom-color: #f44336;
  }
}

.WithStyles\(ForwardRef\(TextField\)\)-root-4 .MuiInput-underline:after {
  border-bottom-color: $primary-color !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 2px solid #EFEFEF !important;
}

.settings {
  .breadcrumb {
    margin-bottom: 28px !important;
  }

  .settings-cont {
    width: 300px;

    .select-input {
      width: 100%;

      .MuiInput-formControl {
        margin-top: 4px;
      }

      .MuiSelect-select {
        display: flex;
        height: 100% !important;
        align-items: center;
      }
    }

    @include media-breakpoint-down-sm {
      width: 100%;
    }

    .MuiInput-underline:before {
      border: none;
      content: "";
      position: inherit;
    }

    .MuiFormControl-root {
      background: #FFFFFF;
      border: 1px solid #F7F7F7;
      box-shadow: 0px 3px 8px rgba(40, 26, 27, 0.1);
      border-radius: 7px;
      padding-left: 5px;
    }

    .MuiSelect-select:focus {
      background-color: $white
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before,
    .MuiInput-underline:after {
      border-bottom: 2px solid #EFEFEF !important;
    }
  }

  .MuiOutlinedInput-root {
    background: #FFFFFF;
    border: none;
    box-shadow: 0px 3px 8px rgba(40, 26, 27, 0.1);
    border-radius: 7px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #F7F7F7;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid #F7F7F7;
  }

  .MuiSelect-icon {
    color: $primary-color;
  }

  .MuiSvgIcon-root {
    font-size: 2rem;
  }
}

.PhoneInputInput {
  border: none !important;
  outline: none !important;
  background: transparent !important;
}

//loginInputs
.phone-input-login {
  @include size(303px, 50px);
  border-radius: 50px;
  border: 1px solid #F5CCD7;
  margin: auto;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: white;

  .PhoneInput {
    margin-left: 39px;
    width: 85%;
  }

  .PhoneInputInput {
    border: none;
    outline: none;
    margin-left: 7px;
  }
}

.regis-check {
  width: 303px;
  margin-top: 24px;

  .form-check-input {
    border-color: $primary-color !important;
    border: 1px solid $primary-color;
  }

  .form-check-input:checked {
    background-color: $primary-color !important;
    border-color: $primary-color;
  }

  .form-check-input:focus {
    box-shadow: none;
  }

  .form-check-label {
    display: flex;
    flex-direction: column;
    color: #F99A9E !important;
    font-weight: $regular;
    font-size: $fz-12;
    line-height: 14px;

    a {
      text-decoration: underline !important;
    }
  }
}

.select-input .MuiInputBase-input {
  height: fit-content !important;
}

// .login-inputs{
//   margin-bottom: 20px;
//   .MuiFormControl-root{
//     @include size(303px,50px)
//   }
//   .MuiOutlinedInput-root fieldset{
//     border-radius: 50px;
//     border: 1px solid #F5CCD7;
//   }
// }
//comments input
// .MuiOutlinedInput-notchedOutline {
//   border: none !important
// }

.active-input {

  // d-flex align-items-center active-input w-100 p-2 justify-content-between
  // margin-top: 21px;
  img {
    width: 32px !important;
    height: 32px !important;
    margin-bottom: 0px !important;
  }

  padding: 5px 10px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  background: #FFFFFF;
  opacity: 0.98;
  border:none;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);

  @include media-breakpoint-down-sm {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
  }


  img {
    @include size(32px);
  }

  .send {
    cursor: pointer;
    background: #E2878B;
    opacity: 0.98;
    border-radius: 10px;
    @include size(46px);

    img {
      @include size(19px);
      margin: 0;
    }
  }
}

.comment-input,
.active-input {
  margin-top: 21px;

  input {
    outline: none !important;
    border: none !important;
    flex: 1;

    &::placeholder {
      font-size: 14px !important;
      line-height: 16px !important;
      color: #D1D1D1 !important;
    }
  }
}

textarea {
  outline: none !important;
}

//posts
.MuiExpansionPanelSummary-content.Mui-expanded,
.MuiExpansionPanelSummary-content {
  margin: 0 !important;
  min-height: 33px !important;
}

.MuiAccordionSummary-content {
  margin: 0 !important;
}

.MuiAccordionSummary-root {
  min-height: 35px !important;
}

.MuiExpansionPanelSummary-root {
  min-height: fit-content !important;
}

.contact-phone-input {
  .form-control {
    box-shadow: none !important;
  }
}

.react-tel-input {
  width: fit-content !important;

  .form-control {
    width: 230px !important;
  }

  .form-control.invalid-number {
    border: none !important;
    background-color: $white !important;

    &:focus {
      border: none !important;
      background-color: $white !important;
      box-shadow: none !important;
    }
  }

  .flag-dropdown.invalid-number {
    border: none !important;
    background-color: $white !important;
  }

  .selected-flag {
    padding: 0 !important;
  }
}

.avatarOption {
  position: absolute !important;
  right: -43px !important;
  top: 22px !important;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg);
}

.needs-validation {
  .form-control {
    display: flex !important;
    align-items: center !important;
  }

  input {
    width: 100%;
    border: none;
    outline: none !important;
  }

  .form-control,
  .form-control-textarea {
    @include size(100%, 50px);
    // background: $white;
    box-shadow: inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px !important;
    border: none;
    outline: none;
    padding-left: 11px;
  }

  .form-control-textarea {
    height: 236px;
  }

  .form-control:disabled,
  .form-control:read-only {
    background-color: white !important;
  }

  // .was-validated .form-control:invalid, .form-control.is-invalid{
  //   border-color:white !important;
  // }
}

.modal-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: $medium;
  font-size: $fz-16;
  line-height: 19px;
  text-align: center;
  color: $text-color;
  margin-bottom: 24px !important;
}

.button-title {
  font-size: $fz-16;
  line-height: 19px;
  text-align: center;
  color: #F9999D;
  cursor: pointer;
}

.modal-line {
  height: 1px;
  width: 90%;
  background: #F0F0F0;
  margin-bottom: 9px;
}

// .PhoneInput:hover .modal-line{
//   background-color: 1px solid $primary-light-shade-color;
// }

.home-slider-cont {
  .slick-slide {
    @include media-breakpoint-down-sm {
      height: 101px;
    }
  }
}

.comment-slider {
  display: none;
  position: relative;
  @include media-breakpoint-down-sm {
    display: flex;
  }
  .slick-track{
    margin-bottom: 0 !important;
  }
  .slick-slider {
    width: 100%;

    .slick-list {
      width: 100%;
    }
  }
}

.pagination {
  li {
    // @include size(30px);
    font-size: $fz-18;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    position: relative;
    float: left;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.5;
    text-decoration: none;
    border: 1px solid #ddd;
    cursor: pointer;
  }

  .active {
    color: $white;
    cursor: default;
    background-color: $primary-color;
    border-color: $primary-color;
  }

}

.customMarker {
  position: absolute;
  cursor: pointer;
  background: #424242;
  width: 100px;
  height: 100px;
  /* -width/2 */
  margin-left: -50px;
  /* -height + arrow */
  margin-top: -110px;
  border-radius: 50px;
  padding: 0px;
}

.customMarker:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 40px;
  border-width: 10px 10px 0;
  border-style: solid;
  border-color: #424242 transparent;
  display: block;
  width: 0;
}

.customMarker img {
  width: 90px;
  height: 90px;
  margin: 5px;
  border-radius: 50px;
}

//map
img~map {
  border-radius: 50% !important;
}

.marker {
  .label-hover {
    display: none;
    position: absolute;
    background-color: white;
    padding: 20px;
    bottom: 0px;
    left: 20px;
    border-radius: 10px;
  }

  .label-item {
    width: 40px;
    height: 46px;
    margin-top: -45px;
    margin-left: -20px;
    cursor: pointer;
    z-index: 888888888888888888888888;
    position: absolute;
  }

  &:hover {
    .label-hover {
      display: flex;
    }
  }
}

.icon-send {
  font-size: 20px !important;
}

.icon-pay {
  margin-right: 7px;
}

.icon-padlock:before,
.icon-envelop:before {
  font-size: 20px;
  margin-left: 21px;
}

.PhoneInputCountryIconImg {
  color: #977575 !important;
}

// .fslightbox-source{
//   width: 500px !important;
//   max-width: 100% !important;
// }
.post-video {
  height: 255px;

  .video-react .video-react-video {
    width: 100%;
    top: -17px;
    left: 0px;

  }
}

.icon-images {
  margin-bottom: 10px;
  font-size: 20px;
}

.icon-delete {
  cursor: pointer;
}

.cs-message-group__messages {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cities-list {
  max-height: 400px;
  padding-bottom: 20px;
  width: 600px;
  background-color: #fff !important;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
}

.cities-list .MuiCardActions-root {
  // display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0px 16px 16px 16px;
  height: 100%;
}

.cities-list .MuiCardActions-root a {
  margin-left: 0;
  font-size: 16px;
  margin: 0 25px 15px 0;
  position: relative;
  transition: all .3s;
}

.cities-list .MuiCardActions-root a:hover {
  color: #F9989C !important;
}

.cities-list .MuiCardActions-root a::after {
  content: '';
  display: inline-block;
  background: #F9989C;
  width: 100%;
  height: 1px;
  bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
  left: 0;
}

// .cities-list .MuiCardActions-root a:hover::after{
//   width: 100%;
//   transition: width .3s ease-in-out;
// }
.masters {
  .MuiInputLabel-formControl {
    left: -5px !important;
    top: -2px;
  }
}

@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px !important;
    --bs-gutter-x: 0.35rem !important;
  }
}

.ant-picker-panel-container {
  box-shadow: none !important;
}

.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: rgb(249, 152, 156, 0.4) !important;
}

.ant-btn-primary {
  background-color: $primary-color !important;
  box-shadow: none !important;
  color: white !important;
}

.ant-picker:hover {
  border-color: $primary-color !important
}

.ant-tabs-tab-btn {
  color: $primary-color !important;
}

.ant-tabs-ink-bar {
  background: $primary-color !important;
}

.ant-picker-active-bar {
  background: $primary-color !important;
}

.block-drawer-content {
  display: flex;
  flex-direction: column !important;
}
.animated_heart{
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  img{
      width: 400px !important;
      height: auto !important;
      border: none !important;
      @media (min-width: 400px) {
        width: 250px !important;
      }
  }
}