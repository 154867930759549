@font-face {
  font-family: 'icomoon';
  src:  url('/icomoon/icomoon.eot?y1gt9p');
  src:  url('/icomoon/icomoon.eot?y1gt9p#iefix') format('embedded-opentype'),
    url('/icomoon/icomoon.ttf?y1gt9p') format('truetype'),
    url('/icomoon/icomoon.woff?y1gt9p') format('woff'),
    url('/icomoon/icomoon.svg?y1gt9p#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal; 
  text-transform: none;
  line-height: 1;
  cursor: pointer;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
  content: "\e900";
}
.icon-arrow-left:before {
  content: "\e901";
  color: #c4c4c4;
}
.icon-calendar:before {
  content: "\e902";
}
.icon-camera:before {
  content: "\e903";
}
.icon-chat:before {
  content: "\e904";
  color: #e2878b;
}
.icon-chat_1:before {
  content: "\e905";
  color: white;
}
.icon-check:before {
  content: "\e906";
  color: #e2878b;
}
.icon-clock:before {
  content: "\e907";
  color: #afafaf;
}
.icon-copy:before {
  content: "\e908";
}
.icon-credit-card .path1:before {
  content: "\e909";
  color: rgb(255, 255, 255);
}
.icon-credit-card .path2:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-credit-card .path3:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-credit-card .path4:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-credit-card .path5:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-credit-card .path6:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  margin-right: 10px;
}
.icon-cross:before {
  content: "\e90f";
  color: #c4c4c4;
}
.icon-delete:before {
  content: "\e910";
}
.icon-edit:before {
  content: "\e911";
  color: #38442e;
}
.icon-envelop:before {
  content: "\e912";
  color: #977575;
}
.icon-heart:before {
  content: "\e913";
  color: #fff;
}
.icon-images:before {
  content: "\e914";
}
.icon-Information .path1:before {
  content: "\e915";
  color: rgb(249, 157, 161);
}
.icon-Information .path2:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Information .path3:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(249, 157, 161);
}
.icon-Information .path4:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Information .path5:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(249, 157, 161);
}
.icon-Information .path6:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-log-out:before {
  content: "\e91b";
}
.icon-map-label:before {
  content: "\e91c";
  color: #e2878b;
}
.icon-notifications:before {
  content: "\e91d";
  color: #fff;
}
.icon-padlock:before {
  content: "\e91e";
  color: #977575;
}
.icon-pay:before {
  content: "\e91f";
  color: #afafaf;
}
.icon-phone:before {
  content: "\e920";
  color: #977575;
}
.icon-plus:before {
  content: "\e921";
  color: #e2878b;
}
.icon-publications:before {
  content: "\e922";
}
.icon-search:before {
  content: "\e923";
  color: #fff;
}
.icon-send:before {
  content: "\e924";
  color: #fff;
}
.icon-services:before {
  content: "\e925";
}
.icon-settings-map:before {
  content: "\e926";
  color: #e2878b;
}
.icon-testimonials:before { 
  content: "\e927";
}

.icon-testimonials1:before {
  content: "\e927";
}
.icon-user:before {
  content: "\e928";
  color: #977575;
}
.icon-viewing:before {
  content: "\e929";
  color: white;
}


// @font-face {
//     font-family: 'icomoon';
//     src:  url('/icomoon/icomoon.eot?ewsiec');
//     src:  url('/icomoon/icomoon.eot?ewsiec#iefix') format('embedded-opentype'),
//       url('/icomoon/icomoon.ttf?ewsiec') format('truetype'),
//       url('/icomoon/icomoon.woff?ewsiec') format('woff'),
//       url('/icomoon/icomoon.svg?ewsiec#icomoon') format('svg');
//     font-weight: normal;
//     font-style: normal;
//     font-display: block;
//   }
//   [class^="icon-"], [class*=" icon-"] {
//     /* use !important to prevent issues with browser extensions that change fonts */
//     font-family: 'icomoon' !important;
//     speak: never;
//     font-style: normal;
//     font-weight: normal;
//     font-variant: normal;
//     text-transform: none;
//     line-height: 1;
  
//     /* Better Font Rendering =========== */
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//   }
  
//   .icon-arrow:before {
//     content: "\e900";
//   }
//   .icon-arrow-left:before { 
//     content: "\e901";
//     color: #c4c4c4;
//   }
//   .icon-calendar:before {
//     content: "\e902";
//   }
//   .icon-camera:before {
//     content: "\e903";
//   }
//   .icon-card .path1:before {
//     content: "\e904";
//     color: rgb(255, 255, 255);
//   }
//   .icon-card .path2:before {
//     content: "\e905";
//     margin-left: -1em;
//     color: rgb(0, 0, 0);
//   }
//   .icon-card .path3:before {
//     content: "\e906";
//     margin-left: -1em;
//     color: rgb(255, 255, 255);
//   }
//   .icon-card .path4:before {
//     content: "\e907";
//     margin-left: -1em;
//     color: rgb(0, 0, 0);
//   }
//   .icon-card .path5:before {
//     content: "\e908";
//     margin-left: -1em;
//     color: rgb(255, 255, 255);
//   }
//   .icon-card .path6:before {
//     content: "\e909";
//     margin-left: -1em;
//     color: rgb(0, 0, 0);
//   }
//   .icon-chat:before {
//     content: "\e90a";
//     color: #e2878b;
//   }
//   .icon-chat_1:before {
//     content: "\e90b";
//     color: #d2d2d2;
//   }
//   .icon-checks:before {
//     content: "\e90c";
//   }
//   .icon-clock:before {
//     content: "\e90d";
//     color: #afafaf;
//   }
//   .icon-copy:before {
//     content: "\e90e";
//   }
//   .icon-cross:before {
//     content: "\e90f";
//   }
//   .icon-delete:before {
//     content: "\e910";
//   }
//   .icon-heart:before {
//     content: "\e911";
//     color: #f58388;
//   }
//   .icon-images:before {
//     content: "\e912";
//   }
//   .icon-Information .path1:before {
//     content: "\e913";
//     color: rgb(249, 157, 161);
//   }
//   .icon-Information .path2:before {
//     content: "\e914";
//     margin-left: -1em;
//     color: rgb(0, 0, 0);
//   }
//   .icon-Information .path3:before {
//     content: "\e915";
//     margin-left: -1em;
//     color: rgb(249, 157, 161);
//   }
//   .icon-Information .path4:before {
//     content: "\e916";
//     margin-left: -1em;
//     color: rgb(0, 0, 0);
//   }
//   .icon-Information .path5:before {
//     content: "\e917";
//     margin-left: -1em;
//     color: rgb(249, 157, 161);
//   }
//   .icon-Information .path6:before {
//     content: "\e918";
//     margin-left: -1em;
//     color: rgb(0, 0, 0);
//   }
//   .icon-line:before {
//     content: "\e919";
//   }
//   .icon-log-out:before {
//     content: "\e91a";
//   }
//   .icon-map-label:before {
//     content: "\e91b";
//     color: #e2878b;
//   }
//   .icon-notifications:before {
//     content: "\e91c";
//     color: #fff;
//   }
//   .icon-pay:before {
//     content: "\e91d";
//     color: #afafaf;
//   }
//   .icon-plus:before {
//     content: "\e91e";
//     color: #afafaf;
//   }
//   .icon-publications .path1:before {
//     content: "\e91f";
//     color: rgb(249, 157, 161);
//   }
//   .icon-publications .path2:before {
//     content: "\e920";
//     margin-left: -1em;
//     color: rgb(255, 255, 255);
//   }
//   .icon-publications .path3:before {
//     content: "\e921";
//     margin-left: -1em;
//     color: rgb(249, 157, 161);
//   }
//   .icon-publications .path4:before {
//     content: "\e922";
//     margin-left: -1em;
//     color: rgb(255, 255, 255);
//   }
//   .icon-publications .path5:before {
//     content: "\e923";
//     margin-left: -1em;
//     color: rgb(249, 157, 161);
//   }
//   .icon-publications .path6:before {
//     content: "\e924";
//     margin-left: -1em;
//     color: rgb(255, 255, 255);
//   }
//   .icon-publications .path7:before {
//     content: "\e925";
//     margin-left: -1em;
//     color: rgb(249, 157, 161);
//   }
//   .icon-publications .path8:before {
//     content: "\e926";
//     margin-left: -1em;
//     color: rgb(255, 255, 255);
//   }
//   .icon-send:before {
//     content: "\e927";
//     color: #fff;
//   }
//   .icon-services:before {
//     content: "\e928";
//   }
//   .icon-star .path1:before {
//     content: "\e929";
//     color: rgb(250, 164, 168);
//   }
//   .icon-star .path2:before {
//     content: "\e92a";
//     margin-left: -1em;
//     color: rgb(0, 0, 0);
//   }
//   .icon-testimonials:before {
//     content: "\e92b";
//   }
//   .icon-viewing:before {
//     content: "\e92c";
//     color: #acacac;
//   }
