@import './../../styles/var';
@import './../../styles/mixins';
.layer{
   position: fixed;
   top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FBF3EF;
    opacity: 0.8;
    z-index: 1000000;
}